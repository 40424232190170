/*export const accountTypes = {
    man: 'MAN',
    woman: 'WOMAN',
    couple: 'COUPLE',

    isCouple: (type) => (type === accountTypes.couple),
    showMan: (type) => ([accountTypes.man, accountTypes.couple].includes(type)),
    showWoman: (type) => ([accountTypes.woman, accountTypes.couple].includes(type)),

    showManFields: (types) => (types && (types.includes(accountTypes.man) || types.includes(accountTypes.couple))),
    showWomanFields: (types) => (types && (types.includes(accountTypes.woman) || types.includes(accountTypes.couple))),
}*/

/*export const getStatusById = (id, statuses) => {
    let res = statuses.find(x => x.id === id);
    //console.log('id, statuses===>', id, statuses, res);
    if (res)
        return {
            name: res.name,
            short_name: res.short_name ? res.short_name : res.id,
            img: res.image ? res.image : '',
            color: res.color ? res.color : '',
        };
    else return {name: '', short_name: '', img: '', color: ''};
    // for (const x of statuses) { if (x.id===id) return `${x.image}`}
}*/
export const getStatusById = (id, statuses) => {
    let res = statuses.find(x => x.id === id);
    //console.log('id, statuses===>', id, statuses, res);
    if (res)
        return {
            id: res.id,
            name: res.name,
            short_name: res.short_name ? res.short_name : res.id,
            img: res.image ? res.image : '',
            color: res.color ? res.color : '',
        };
    else return {id: false, name: '', short_name: '', img: '', color: ''};
    // for (const x of statuses) { if (x.id===id) return `${x.image}`}
}
//====================== Common ====================================
export const choicesRole = [
    {id: 'Admin', name: 'Admin'},
    {id: 'Main Manager', name: 'Main Manager'},
    {id: 'Analyst', name: 'Analyst'},
    {id: 'Supervisor', name: 'Supervisor'},
    {id: 'Sales Representative', name: 'Sales Representative'},
    {id: 'Manager', name: 'Manager'},
    {id: 'Logistician', name: 'Logistician'},
    // {id: 'AdminTest', name: 'AdminTest'},
]
export const choicesFieldsForExcel = [
    {id: 'id', name: 'id'},
    {id: 'name', name: 'name'},
    {id: 'sku', name: 'sku'},
    {id: 'article', name: 'article'},
    {id: 'description', name: 'description'},
    {id: 'category', name: 'category'},
    {id: 'brand', name: 'brand'},
    {id: 'basePrice', name: 'basePrice'},
    {id: 'quantityInt', name: 'quantityInt'},

    {id: 'properties', name: 'properties'},
]
export const choicesDisplayOption = [
    {id: 'checkbox', name: 'checkbox'},
    {id: 'selector', name: 'selector'},
    {id: 'radiobutton', name: 'radiobutton'},
    {id: 'multiselect', name: 'multiselect'},
]
export const choicesPromotionType = [
    {id: 'GOODS_AS_A_GIFT', name: 'GOODS AS A GIFT'},
    {id: 'SET_OF_GOODS', name: 'SET OF GOODS'},
    {id: 'FROM_THE_QUANTITY_OF_GOODS', name: 'FROM THE QUANTITY OF GOODS'},
    {id: 'FROM_THE_SUM_OF_ORDER', name: 'FROM THE SUM OF ORDER'},
    {id: 'NO_CONDITION', name: 'NO CONDITION'},
]

export const choicesDiscountMode = [
    {id: 'discount_sum', name: 'Discount sum'},
    {id: 'discount_max', name: 'Discount max'},
    {id: 'cumulative_total', name: 'Cumulative total'},
]
export const choicesPriceMode = [
    {id: 'standard', name: 'Standard'},
    {id: 'additional_discount', name: 'Additional discount'},
]

export const choicesExternalSupplier = [
    {id: 'BDroppy', name: 'BDroppy'},
    {id: 'Samange', name: 'Samange'},
]
export const choicesPaymentSystemCode = [
    {id: 'fondy', name: 'Fondy'},
    {id: 'stripe', name: 'Stripe'},
]

export const choicesDiscountAlgorithm = [
    {id: 'BY_DISCOUNT_SUPPLIER_CUSTOMER', name: 'BY_DISCOUNT_SUPPLIER_CUSTOMER'},
    {id: 'BY_DISCOUNT_PRODUCT_CATEGORY', name: 'BY_DISCOUNT_PRODUCT_CATEGORY'},
    {id: 'BY_PRICE_TYPES', name: 'BY_PRICE_TYPES'},
    {id: 'BY_PRICE_SCALE', name: 'BY_PRICE_SCALE'},
]

export const choicesHowShowQuantity = {
    en: [
        {id: 'EXACTLY', name: 'Точное'},
        {
            id: 'INTERVAL>5',
            name: 'Если остаток меньше или равно 5 - отображается точное кол-во, если больше 5 - отображается ">5"'
        },
        {
            id: 'INTERVAL>10',
            name: 'Если остаток меньше или равно 10 - отображается точное кол - во, если больше 10 - отображается ">10"'
        },
        {
            id: 'STARS',
            name: 'Звездочки - (>=1 К-во <5 – одна звездочка) (>=5 К-во <10 – две звездочки) (>10 – Три звездочки) ***'
        },
        {id: 'YES_NO', name: 'Да / Нет'},
        {id: 'NO_SHOW', name: 'Не показывать'},
    ],
    ru: [
        {id: 'EXACTLY', name: 'Точное'},
        {
            id: 'INTERVAL>5',
            name: 'Если остаток меньше или равно 5 - отображается точное кол-во, если больше 5 - отображается ">5"'
        },
        {
            id: 'INTERVAL>10',
            name: 'Если остаток меньше или равно 10 - отображается точное кол - во, если больше 10 - отображается ">10"'
        },
        {
            id: 'STARS',
            name: 'Звездочки - (>=1 К-во <5 – одна звездочка) (>=5 К-во <10 – две звездочки) (>10 – Три звездочки) ***'
        },
        {id: 'YES_NO', name: 'Да / Нет'},
        {id: 'NO_SHOW', name: 'Не показывать'},
    ],
    uk: [
        {id: 'EXACTLY', name: 'Точное'},
        {
            id: 'INTERVAL>5',
            name: 'Якщо залишок менше або дорівнює 5 - відображається точна кількість, якщо більше 5 - відображається ">5"'
        },
        {
            id: 'INTERVAL>10',
            name: 'Якщо залишок менше або дорівнює 10 - відображається точна кількість, якщо більше 10 - відображається ">10"'
        },
        {
            id: 'STARS',
            name: 'Зірочки - (>=1 Кількість <5 – одна зірочка) (>=5 Кількість <10 – дві зірочки) (>=10 – три зірочки) ***'
        },
        {id: 'YES_NO', name: 'Так / Ні'},
        {id: 'NO_SHOW', name: 'Не показувати'},
    ],

}
export const choicesBusinessType = {
    en: [
        {id: '', name: '-----'},
        {id: 1, name: '1. СТО'},
        {id: 2, name: '2. Магазин запчастей'},
    ],
    ru: [
        {id: '', name: '-----'},
        {id: 1, name: '1. СТО'},
        {id: 2, name: '2. Магазин запчастей'},
    ],
    uk: [
        {id: '', name: '-----'},
        {id: 1, name: '1. СТО'},
        {id: 2, name: '2. Магазин запчастин'},
    ],
}
export const choicesNdsKey = {
    en: [
        {id: '', name: '-----'},
        {id: 'yes', name: 'Yes'},
        {id: 'no', name: 'No'},
    ],
    ru: [
        {id: '', name: '-----'},
        {id: 'yes', name: 'Да'},
        {id: 'no', name: 'Нет'},
    ],
    uk: [
        {id: '', name: '-----'},
        {id: 'yes', name: 'Так'},
        {id: 'no', name: 'Ні'},
    ],
}
export const choicesContractorType = {
    en: [
        {id: "", "name": "-----"},
        {id: "PP", name: "Private individual"},
        {id: "CO", name: "Company"},
        // {id: "IE", name: "Private entrepreneur"},
        {id: "PE", name: "Individual Entrepreneur"},
        {id: "CU", name: "Legal Entity Outside Ukraine"},
    ],
    ru: [
        {id: '', name: '-----'},
        {id: 'PP', name: 'Частное лицо'},
        {id: 'CO', name: 'Компания'},
        // {id: 'IE', name: 'Частный предприниматель'},
        {id: "PE", name: "Физическое лицо-предприниматель"},
        {id: "CU", name: "Юр. лицо, за пределами Украины"},
    ],
    uk: [
        {id: '', name: '-----'},
        {id: 'PP', name: 'Приватна особа'},
        {id: 'CO', name: 'Компанія'},
        // {id: 'IE', name: 'Приватний підприємець'},
        {id: "PE", name: "Фізична особа-підприємець"},
        {id: "CU", name: "Юр. особа, за межами України"},
    ],
}

export const choicesSizeSystem = [
    {id: '', name: 'Not selected'},
    {id: 'AU', name: 'AU'},
    {id: 'BR', name: 'BR'},
    {id: 'CN', name: 'CN'},
    {id: 'DE', name: 'DE'},
    {id: 'EU', name: 'EU'},
    {id: 'FR', name: 'FR'},
    {id: 'IT', name: 'IT'},
    {id: 'JP', name: 'JP'},
    {id: 'MEX', name: 'MEX'},
    {id: 'UK', name: 'UK'},
    {id: 'US', name: 'US'},
]

export const choicesDefaultLanguage = [
    {"code": "aa", "name": "Afar"},
    {"code": "ab", "name": "Abkhazian"},
    {"code": "ae", "name": "Avestan"},
    {"code": "af", "name": "Afrikaans"},
    {"code": "ak", "name": "Akan"},
    {"code": "am", "name": "Amharic"},
    {"code": "an", "name": "Aragonese"},
    {"code": "ar", "name": "Arabic"},
    {"code": "as", "name": "Assamese"},
    {"code": "av", "name": "Avaric"},
    {"code": "ay", "name": "Aymara"},
    {"code": "az", "name": "Azerbaijani"},
    {"code": "ba", "name": "Bashkir"},
    {"code": "be", "name": "Belarusian"},
    {"code": "bg", "name": "Bulgarian"},
    {"code": "bh", "name": "Bihari languages"},
    {"code": "bi", "name": "Bislama"},
    {"code": "bm", "name": "Bambara"},
    {"code": "bn", "name": "Bengali"},
    {"code": "bo", "name": "Tibetan"},
    {"code": "br", "name": "Breton"},
    {"code": "bs", "name": "Bosnian"},
    {"code": "ca", "name": "Catalan; Valencian"},
    {"code": "ce", "name": "Chechen"},
    {"code": "ch", "name": "Chamorro"},
    {"code": "co", "name": "Corsican"},
    {"code": "cr", "name": "Cree"},
    {"code": "cs", "name": "Czech"},
    {
        "code": "cu",
        "name": "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic"
    },
    {"code": "cv", "name": "Chuvash"},
    {"code": "cy", "name": "Welsh"},
    {"code": "da", "name": "Danish"},
    {"code": "de", "name": "German"},
    {"code": "dv", "name": "Divehi; Dhivehi; Maldivian"},
    {"code": "dz", "name": "Dzongkha"},
    {"code": "ee", "name": "Ewe"},
    {"code": "el", "name": "Greek, Modern (1453-)"},
    {"code": "en", "name": "English"},
    {"code": "eo", "name": "Esperanto"},
    {"code": "es", "name": "Spanish; Castilian"},
    {"code": "et", "name": "Estonian"},
    {"code": "eu", "name": "Basque"},
    {"code": "fa", "name": "Persian"},
    {"code": "ff", "name": "Fulah"},
    {"code": "fi", "name": "Finnish"},
    {"code": "fj", "name": "Fijian"},
    {"code": "fo", "name": "Faroese"},
    {"code": "fr", "name": "French"},
    {"code": "fy", "name": "Western Frisian"},
    {"code": "ga", "name": "Irish"},
    {"code": "gd", "name": "Gaelic; Scomttish Gaelic"},
    {"code": "gl", "name": "Galician"},
    {"code": "gn", "name": "Guarani"},
    {"code": "gu", "name": "Gujarati"},
    {"code": "gv", "name": "Manx"},
    {"code": "ha", "name": "Hausa"},
    {"code": "he", "name": "Hebrew"},
    {"code": "hi", "name": "Hindi"},
    {"code": "ho", "name": "Hiri Motu"},
    {"code": "hr", "name": "Croatian"},
    {"code": "ht", "name": "Haitian; Haitian Creole"},
    {"code": "hu", "name": "Hungarian"},
    {"code": "hy", "name": "Armenian"},
    {"code": "hz", "name": "Herero"},
    {
        "code": "ia",
        "name": "Interlingua (International Auxiliary Language Association)"
    },
    {"code": "id", "name": "Indonesian"},
    {"code": "ie", "name": "Interlingue; Occidental"},
    {"code": "ig", "name": "Igbo"},
    {"code": "ii", "name": "Sichuan Yi; Nuosu"},
    {"code": "ik", "name": "Inupiaq"},
    {"code": "io", "name": "Ido"},
    {"code": "is", "name": "Icelandic"},
    {"code": "it", "name": "Italian"},
    {"code": "iu", "name": "Inuktitut"},
    {"code": "ja", "name": "Japanese"},
    {"code": "jv", "name": "Javanese"},
    {"code": "ka", "name": "Georgian"},
    {"code": "kg", "name": "Kongo"},
    {"code": "ki", "name": "Kikuyu; Gikuyu"},
    {"code": "kj", "name": "Kuanyama; Kwanyama"},
    {"code": "kk", "name": "Kazakh"},
    {"code": "kl", "name": "Kalaallisut; Greenlandic"},
    {"code": "km", "name": "Central Khmer"},
    {"code": "kn", "name": "Kannada"},
    {"code": "ko", "name": "Korean"},
    {"code": "kr", "name": "Kanuri"},
    {"code": "ks", "name": "Kashmiri"},
    {"code": "ku", "name": "Kurdish"},
    {"code": "kv", "name": "Komi"},
    {"code": "kw", "name": "Cornish"},
    {"code": "ky", "name": "Kirghiz; Kyrgyz"},
    {"code": "la", "name": "Latin"},
    {"code": "lb", "name": "Luxembourgish; Letzeburgesch"},
    {"code": "lg", "name": "Ganda"},
    {"code": "li", "name": "Limburgan; Limburger; Limburgish"},
    {"code": "ln", "name": "Lingala"},
    {"code": "lo", "name": "Lao"},
    {"code": "lt", "name": "Lithuanian"},
    {"code": "lu", "name": "Luba-Katanga"},
    {"code": "lv", "name": "Latvian"},
    {"code": "mg", "name": "Malagasy"},
    {"code": "mh", "name": "Marshallese"},
    {"code": "mi", "name": "Maori"},
    {"code": "mk", "name": "Macedonian"},
    {"code": "ml", "name": "Malayalam"},
    {"code": "mn", "name": "Mongolian"},
    {"code": "mr", "name": "Marathi"},
    {"code": "ms", "name": "Malay"},
    {"code": "mt", "name": "Maltese"},
    {"code": "my", "name": "Burmese"},
    {"code": "na", "name": "Nauru"},
    {
        "code": "nb",
        "name": "Bokmål, Norwegian; Norwegian Bokmål"
    },
    {"code": "nd", "name": "Ndebele, North; North Ndebele"},
    {"code": "ne", "name": "Nepali"},
    {"code": "ng", "name": "Ndonga"},
    {"code": "nl", "name": "Dutch; Flemish"},
    {"code": "nn", "name": "Norwegian Nynorsk; Nynorsk, Norwegian"},
    {"code": "no", "name": "Norwegian"},
    {"code": "nr", "name": "Ndebele, South; South Ndebele"},
    {"code": "nv", "name": "Navajo; Navaho"},
    {"code": "ny", "name": "Chichewa; Chewa; Nyanja"},
    {"code": "oc", "name": "Occitan (post 1500)"},
    {"code": "oj", "name": "Ojibwa"},
    {"code": "om", "name": "Oromo"},
    {"code": "or", "name": "Oriya"},
    {"code": "os", "name": "Ossetian; Ossetic"},
    {"code": "pa", "name": "Panjabi; Punjabi"},
    {"code": "pi", "name": "Pali"},
    {"code": "pl", "name": "Polish"},
    {"code": "ps", "name": "Pushto; Pashto"},
    {"code": "pt", "name": "Portuguese"},
    {"code": "qu", "name": "Quechua"},
    {"code": "rm", "name": "Romansh"},
    {"code": "rn", "name": "Rundi"},
    {"code": "ro", "name": "Romanian; Moldavian; Moldovan"},
    {"code": "ru", "name": "Russian"},
    {"code": "rw", "name": "Kinyarwanda"},
    {"code": "sa", "name": "Sanskrit"},
    {"code": "sc", "name": "Sardinian"},
    {"code": "sd", "name": "Sindhi"},
    {"code": "se", "name": "Northern Sami"},
    {"code": "sg", "name": "Sango"},
    {"code": "si", "name": "Sinhala; Sinhalese"},
    {"code": "sk", "name": "Slovak"},
    {"code": "sl", "name": "Slovenian"},
    {"code": "sm", "name": "Samoan"},
    {"code": "sn", "name": "Shona"},
    {"code": "so", "name": "Somali"},
    {"code": "sq", "name": "Albanian"},
    {"code": "sr", "name": "Serbian"},
    {"code": "ss", "name": "Swati"},
    {"code": "st", "name": "Sotho, Southern"},
    {"code": "su", "name": "Sundanese"},
    {"code": "sv", "name": "Swedish"},
    {"code": "sw", "name": "Swahili"},
    {"code": "ta", "name": "Tamil"},
    {"code": "te", "name": "Telugu"},
    {"code": "tg", "name": "Tajik"},
    {"code": "th", "name": "Thai"},
    {"code": "ti", "name": "Tigrinya"},
    {"code": "tk", "name": "Turkmen"},
    {"code": "tl", "name": "Tagalog"},
    {"code": "tn", "name": "Tswana"},
    {"code": "to", "name": "Tonga (Tonga Islands)"},
    {"code": "tr", "name": "Turkish"},
    {"code": "ts", "name": "Tsonga"},
    {"code": "tt", "name": "Tatar"},
    {"code": "tw", "name": "Twi"},
    {"code": "ty", "name": "Tahitian"},
    {"code": "ug", "name": "Uighur; Uyghur"},
    {"code": "uk", "name": "Ukrainian"},
    {"code": "ur", "name": "Urdu"},
    {"code": "uz", "name": "Uzbek"},
    {"code": "ve", "name": "Venda"},
    {"code": "vi", "name": "Vietnamese"},
    {"code": "vo", "name": "Volapük"},
    {"code": "wa", "name": "Walloon"},
    {"code": "wo", "name": "Wolof"},
    {"code": "xh", "name": "Xhosa"},
    {"code": "yi", "name": "Yiddish"},
    {"code": "yo", "name": "Yoruba"},
    {"code": "za", "name": "Zhuang; Chuang"},
    {"code": "zh", "name": "Chinese"},
    {"code": "zu", "name": "Zulu"}
]

export const choicesWeekday = [
    {id: '', name: '---'},
    {id: 'sunday', name: 'Sunday'},
    {id: 'monday', name: 'Monday'},
    {id: 'tuesday', name: 'Tuesday'},
    {id: 'wednesday', name: 'Wednesday'},
    {id: 'thursday', name: 'Thursday'},
    {id: 'friday', name: 'Friday'},
    {id: 'saturday', name: 'Saturday'},
]
export const choicesTypeEmail = [
    // {id: null, name: '---'},
    {id: 'sales', name: 'sales'},
    {id: 'order_status&news', name: 'order_status&news'},
    {id: 'support', name: 'support'},
    {id: 'general', name: 'general'},
    {id: 'prices_download', name: 'prices_download'},
]

export const commonYesNo = [
    {id: 'Y', name: 'YES', image: '👍'},
    {id: 'N', name: 'NO', image: '👎'},
]
export const commonTrueFalse = [
    {id: true, name: 'YES', image: '👍'},
    {id: false, name: 'NO', image: '👎'},
]
export const currencyRounding = [
    {id: 'no_rounding', name: 'no_rounding'},
    {id: 'rounded_up', name: 'rounded_up'},
    {id: 'arithmetical_rules', name: 'arithmetical_rules'},
]

export const choicesRuleType = [
    {id: 'customer', name: 'customer', 'protected': false},
    {id: 'product', name: 'product', 'protected': false},
    {id: 'order', name: 'order', filter: false, 'protected': true},
    {id: 'complicated_rule', name: 'complicated_rule', filter: false, 'protected': true},
    {id: 'bonus', name: 'bonus', filter: false, 'protected': true},

]
export const choicesDefaultRelation = [
    {id: 'ignore', name: 'ignore'},
    {id: 'add', name: 'add'},
    {id: 'absorb', name: 'absorb'},
    {id: 'absorbed_by', name: 'absorbed_by'},
    {id: 'maximum', name: 'maximum'},
    {id: 'minimum', name: 'minimum'},
]
//====================== RichTextInput =========================

export const toolbarRichTextInput = [
    // [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
    [{'header': [1, 2, 3, 4, 5, 6, false]}],
    [{'font': []}],

    ['video'],
    // ['insert-code', 'code-insert'],
    ['image', 'link'],

    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    // [{'header': 1}, {'header': 2}],               // custom button values
    [{'list': 'ordered'}, {'list': 'bullet'}],
    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
    [{'direction': 'rtl'}],                         // text direction

    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
    [{'align': []}],

    ['clean']                                         // remove formatting button
    /*
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean']
    */
]