export const transRu = {
    myLocal: {
        columns: 'Колонки',
        hide_filter: 'Скрыть фильтр',
        show_filter: 'Показать фильтр',
    },
    dbFields: {},

    "Code": "Код",

    "Show on creation customer process": "Отображать на форме создания клиента",
    "Partners delivery address": "Адрес доставки партнера",
    "Partners delivery addresses": "Адреса доставки партнера",
    "Select a user from the list": "Выбрать пользователя из списка",
    "Enter new user": "Ввести нового пользователя",
    "Go to client": "Перейти к клиенту",
    "This field already exists": "Такое поле уже существует",
    "Client saved": "Клиент успешно сохранен",
    "Create new client": "Создать нового клиента",
    "Delivery Routes": "Маршруты доставки",
    "Need to specify an address for the counterparty": "Нужно указать адрес для контрагента",
    "Delete": "Удалить",
    "Route": "Маршрут",
    "Routes": "Мршруты",
    "Auto": "Авто.",
    "Create client": "Создать клиента",
    "Partner from list": "Выбрать партнера из списка",
    "New customer": "Новый контрагент",
    "New customers": "Новые контрагенты",
    "New partner": "Создать нового партнера",
    "Create Partner": "Создать партнера",
    "Save": "Сохранить",
    "Accounting phone": "Телефон бухгалтерии",
    "Warehouse phone": "Телефон склада",
    "Accounting email": "Электронная почта бухгалтерии",
    "Partner information": "Информация о партнере",
    "Partner customers": "Контрагенты партнера",
    "Partner address": "Адреса партнера",
    "Delivery": "Доставка",
    "Create partner": "Создать партнера",
    "Create customers": "Создать контрагентов",
    "Customers for partner": "Создание контрагентов для партнера",
    "Add customer": "Добавить еще одного контрагента",
    "Add address": "Добавить еще один адрес",
    "Settings for pricing": "Настройки ценообразования",
    "Common data": "Общие данные",
    "Customer users": "Пользователи контрагента",
    "Back": "Назад",
    "Next": "Далее",
    "Old address": "Ранее добавленные адреса",
    "Address for partner": "Адреса для партнера",
    "Save partners data": "Сохранить данные партнера",
    "Save clients data": "Сохранить данные клиента",
    "New address": "Новый адрес",
    "Select from list": "Выбрать из списка",
    "this value already exists": "такое значение уже существует",

    "Customer property": "Свойства контрагента",

    "Is Active": "Активен",
    "Is Superuser": "Суперпользователь",
    "Is Staff": "Персонал",
    "First Name": "Имя",
    "Last Name": "Фамилия",
    "Partner": "Партнер",
    "Partners": "Партнеры",
    "User": "Пользователь",
    "Username": "Имя пользователя",
    "Users": "Пользователи",
    "Create user": "Создать пользователя",
    "Categories": "Категории",
    "Password": "Пароль",
    "Change password": "Изменить пароль",
    "Confirm password": "Подтвердить пароль",
    "The password is required (length > 5)": "Пароль обязателен (длина > 5)",
    "Password confirmation error": "Ошибка подтверждения пароля",
    //-----------------------
    "Phone": "Телефон",
    "Phones": "Телефоны",
    "Official name": "Официальное наименование",
    "Customer name": "Наименование контрагента",
    "Code from the accounting system": "Код из учетной системы",
    "Client code": "Код клиента",
    "Main warehouse": "Основной склад",
    "Legal address": "Юридический адрес",
    "Physical address": "Физический адрес",
    "Credit limit currency": "Валюта кред. лимита",
    "Credit limit term (days)": "Срок кред. лимита (дней)",
    "Credit limit amount": "Сумма кред. лимита",
    "Contractor type": "Тип контрагента",
    "Business type": "Вид деятельности",
    "VAT payer": "Плательщик НДС",
    "EDRPOU": "ЕГРПОУ",
    "Tax number": "ИНН",
    "VAT identification number": "Ид. номер НДС",
    "How to show quantity": "Как показывать наличие",
    "Retail margin": "Розничная наценка",
    //-----------------------
    "Manager": "Менеджер",
    "Sales representative SNG": "ТП СНГ",
    "Sales representative Ino": "ТП иномарки",

    "Confirm": "Подтверждено",
    "Disable": "Отключен",
    "Address": "Адрес",
    "Saved Address": "Сохраненный адрес",
    "Address lat / lon": "Адрес lat / lon",
    "Area": "Область",
    "Region": "Район",
    "Point": "Населенный пункт",
    "Street": "Улица",
    "House": "Дом",
    "Flat": "Квартира",
    "Deliveries": "Доставки",
    "Comment": "Комментарий",
    "Placement": "Расположение",
    "Partner Address": "Адрес партнера",

    "Check coordinates on the map": "Проверить координаты на карте",
    "Fill in coordinates manually": "Заполнить координаты вручную ",
    "Functionality under development": "Функционал в разработке",
    "Automatic address filling": "Автоматическое заполнение адреса",
    //-----------------------

    ignore: 'игнорировать',

    "Amount (base price)": "Сумма (базовая цена)",
    "Amount (regular price)": "Сумма (регулярная цена)",
    "Total order including discounts": "Итого заказ с учетом скидок",

    add: 'добавить',
    absorb: 'поглощать',
    absorbed_by: 'поглощен (кем/чем)',
    maximum: 'максимум',
    minimum: 'минимум',

    'Active': 'Активный',
    Additionally: 'Дополнительно',
    'Applied Rule': 'Применяемое правило',
    'Absorbed Rule': 'Заменяемое правило',

    'Base price': 'Базовая цена',
    BONUSES: 'БОНУСЫ',
    Bonuses: 'Бонусы',
    'Bonus': 'Бонус',
    'bonus': 'бонус',
    Brands: 'Бренды',
    Brand: 'Бренд',
    brand_ref: 'Бренд (справочник)',
    Brand_ref: 'Бренд (справочник)',
    BrandsSynonyms: 'Синонимы бренда',
    Brandssynonyms: 'Синонимы бренда',
    BrandsReplacement: 'Замены брендов в прайсах поставщиков',
    Brandsreplacement: 'Замены брендов в прайсах поставщиков',
    BrandImage: 'Изображение бренда',
    Brandimage: 'Изображение бренда',
    brands_country: 'Страна бренда',
    'Brands By Rule': 'Бренды по правилам',

    'complicated_rule': 'сложное правило',
    'customer': 'контрагент',
    'Clear Result': 'Очистить результат',
    COMMON: 'ОБЩИЕ',
    Common: 'Общие',
    Characteristics: 'Характеристики',
    Customers: 'Контрагенты',
    Customer: 'Контрагент',
    CREATED: 'СОЗДАНО',
    Created: 'Создано',
    created: 'создано',
    CREATE: 'СОЗДАНО',
    Create: 'Создано',
    create: 'создано',
    Currencies: 'Валюта',
    Currency: 'Валюта',
    'Customer Property Value': 'Значение Свойств Контрагента',
    'Current Discount Rule': 'Текущее Правило Скидки',
    'Customer Discount By Rule': 'Скидка клиента по правилу',
    'Complicated Discount By Rule': 'Сложная скидка по правилу',

    DESCRIPTION: 'ОПИСАНИЕ',
    description: 'описание',
    Description: 'Описание',
    DiscountCustomerBrand: 'Значение дисконта',
    DiscountcustomerBrand: 'Значение дисконта',
    Discount: 'Скидка',
    "Discount Property": 'Свойства Скидок',
    "Discount property": 'Свойства скидок',
    'Discount Rules': 'Правила Скидок',
    'Discount By Rule': 'Скидка по правилу',
    'Discount Property Value': 'Значение Свойств Скидок',
    'Discount property value': 'Значение свойств скидок',
    'Discount Relationship': 'Отношения Скидок',
    'Discount relationship': 'Отношения скидок',
    'Discount Relation Ship': 'Отношения скидок',
    'Default Discount': 'Скидки по умолчанию',
    'Default Relation': 'Отношение по умолчанию',
    'Date From': 'Дата с',
    'Date To': 'Дата по',

    'Earlier': 'Ранее',
    'Export Customer Properties': 'Экспорт свойств контрагента',

    'For All Products': 'Для Всех Товаров',
    'Fix price': 'Фикс. цена',

    'Go Back': 'Вернуться',

    hidden_for_user: 'скрывать для пользователя',
    'History': 'История',

    massive: 'габаритный',

    'Last Rule': 'Последнее правило',

    // NO: 'НЕТ',
    // YES: 'ДА',
    // No: 'Нет',
    // Yes: 'Да',

    name: 'наименование',
    Name: 'Наименование',

    'Order discounts': 'Скидка на заказ',
    original_brand: 'оригинальный бренд',
    Other: 'Другие',
    'Min.amount of non-prom. item': 'Мин.сумма неакц-го товара',
    'Min.order amount': 'Мин.сумма заказа',
    'Min. amount': 'Мин. сумма',

    'Order Quantity': 'Кол-во (заказ)',
    'order': 'заказ',
    'Order': 'Заказ',

    'Quantity': 'Кол-во',
    'quantity': 'Кол-во',

    Prefixes: 'Префикс',
    'Price with order discounts': 'Цена со скидкой при заказе',
    'PRODUCTS': 'ПРОДУКТЫ',
    Protected: 'Защищено',
    protected: 'защищено',
    Products: 'Товары',
    Product: 'Товар',
    'Products By Rule': 'Товары по правилам',
    'Product Property': 'Свойства Товара',
    'Product Property Value': 'Значения Свойств Товара',
    'Price Currency': 'Валюта Цены',
    'Price': 'Цена',
    'product': 'товар',

    'Price Of Week': 'Цена недели',
    'Price of week': 'Цена недели',

    'Regular discounts': 'Регулярные скидки',
    'Regular price': 'Регулярная цена',
    'Related Table': "Доп. Таблицы",
    "Related table": 'Доп. таблицы',
    'Rule type': 'Тип правила',
    'Rule Type': 'Тип Правила',
    'Rate': 'курс',
    'Relaited Discount Rule': 'Правило Связанных Скидок',
    'Relation': 'Отношение',

    // "Brand_ref": 'Бренд (справочник)',
    Index: "Индекс",
    index: "индекс",
    is_new: 'Новый',
    is_offer: 'Акция',
    is_populate: 'Популярный',
    is_sale: 'Распродажа',

    Specification: 'Спецификация',
    sku: 'ску',
    source: 'источник',
    Suffixes: 'Суфикс',
    'Save & Close': 'Сохранить и выйти',
    'Search': 'Поиск',

    'Test': "Проверка",
    'Test Price': 'Проверка цены',
    'Test Order': 'Проверка заказа',
    Today: 'Сегодня',
    'This week': 'Эта неделя',
    'This month': 'Этот месяц',

    Updated: 'Изменено',
    updated: 'изменено',
    Update: 'Изменено',
    update: 'изменено',
    "Units of Measure": 'Еденицы измерения',
    uuid: 'uuid',

    'PROTECTED RECORDS. Creation of related tables prohibited': 'ЗАЩИЩЕННЫЕ ЗАПИСИ. Создание сопутствующих таблиц запрещено',
    'Editing of related tables will be available after record creation': "Редактирование сопутствующих таблиц буде доступно после создания записи",
}

